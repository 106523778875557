import React from "react";
import {Link} from "gatsby";
import logo from "../assets/img/Logo.png";


const Breadcrums = (props) => {

    return(
        <div>
            <div className="page__section">
                <nav className="breadcrumb breadcrumb_type3" aria-label="Breadcrumb">
                    <ol className="breadcrumb__list r-list">
                        <li className="breadcrumb__group">
                            <Link to="/Services" className="breadcrumb__point r-link">
                                Servicios
                            </Link>
                            <span className="breadcrumb__divider" aria-hidden="true">•</span>
                        </li>
                        <li className="breadcrumb__group">
                            <a className="breadcrumb__point r-link">{props.name}</a>
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    )
}

export default Breadcrums

